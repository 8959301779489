import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { alpha, styled } from '@mui/material/styles'
import {
  getMapcollection,
  postMappedApi,
} from '../../../../services/configService'
import { Send } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { SnackbarContext } from '../../../../context/Snackbar'
import { Card, CardContent } from '@mui/material'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'white',
    width: '100%',
    backgroundColor: 'lightslategrey',
  },
}))

export default function Mappinglisting({ state }) {
  const navigate = useNavigate()
  const [data, setData] = React.useState([])
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)

  const [isChecked, setIsChecked] = React.useState([])
  const [editable, SetEditable] = React.useState(false)
  const [error, setError] = React.useState()
  const [disable, setDisable] = React.useState(false)
  const [mappedRowData, setMappedRowdata] = React.useState([])
  const [querycode, setQuerycode] = React.useState('')
  const [checkeddisabled, setCheckeddisabled] = React.useState('')
  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const CustomFooter = () => {
    const amounts =
      Object.values(isChecked).map((item) => {
        return item.amount
      }) || []

    const tdsamounts =
      Object.values(data).map((item) => {
        return item.tds_amt
      }) || []

    const totalTdsAmount = tdsamounts.reduce((sum, amount) => sum + amount, 0)

    const deductionamounts =
      Object.values(data).map((item) => {
        return item.ded_amt
      }) || []

    const totalMappeddedAmount = deductionamounts.reduce(
      (sum, amount) => sum + amount,
      0
    )

    let totalSum = 0
    for (let i = 0; i < amounts.length; i++) {
      totalSum += amounts[i]
    }

    const tdsmappedamounts =
      Object.values(isChecked).map((item) => {
        return item.tds_amt
      }) || []

    let tdstotalSum = 0
    for (let i = 0; i < tdsmappedamounts.length; i++) {
      tdstotalSum += tdsmappedamounts[i]
    }

    const deductmappedamounts =
      Object.values(isChecked).map((item) => {
        return item.ded_amt
      }) || []

    let dedtotalSum = 0
    for (let i = 0; i < deductmappedamounts.length; i++) {
      dedtotalSum += deductmappedamounts[i]
    }

    React.useEffect(() => {
      if (state?.amount - totalSum < 0) {
        setSeverity('error')
        setMes('Amount Exceeds Balance Cheque Amount')
        setOn(true)
      }
    }, [data])
    return (
      <Grid
        container
        gap={2}
        // paddingLeft={2}
        alignItems={'end'}
        // justifyContent={'space-between'}
        borderTop={'1px solid #e5e5e5'}
      >
        <Grid item md={3}></Grid>
        <Grid item md={4}>
          <Stack direction={'column'} spacing={2} margin={1.5}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 500 }}>
                TDS AMT.
                <br />
                (Collection) :
              </p>
              <TextField
                size='small'
                label='TDS Amt.(Collection)'
                className='form_style'
                style={{ width: '50%' }}
                // value={totalTdsAmount}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 500 }}>
                DEDUCTION AMT.
                <br />
                (Collection) :
              </p>
              <TextField
                size='small'
                label='Deduct Amt.(Collection)'
                className='form_style'
                style={{ width: '50%' }}
                // value={totalMappeddedAmount}
              />
            </div>
          </Stack>
        </Grid>
        <Grid item md={4}>
          {/* <Stack direction={'column'} spacing={2} margin={1.5}>
          <p>Total Amount</p>
        </Stack> */}

          <Stack direction={'column'} spacing={2} margin={1.5}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 500 }}>CHEQUE NO. :</p>
              <TextField
                size='small'
                className='form_style'
                label='Cheque No.'
                fullWidth
                value={state?.cheque_no}
                style={{ width: '50%' }} // Adding margin to give some space between the label and input
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 500 }}>CHEQUE AMOUNT :</p>
              <TextField
                size='small'
                className='form_style'
                label='Total Cheque Amount'
                style={{ width: '50%' }}
                value={state?.amount}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 500 }}>MAPPED AMOUNT :</p>

              <TextField
                size='small'
                className='form_style'
                label='Mapped Amount'
                style={{ width: '50%' }}
                value={totalSum}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 500 }}>CHQ BAL AMT :</p>

              <TextField
                size='small'
                className='form_style'
                label='Cheque Balance Amount'
                style={{ width: '50%' }}
                value={state?.amount - totalSum}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 500 }}>
                TDS AMT.
                <br />
                (Mapped) :
              </p>

              <TextField
                size='small'
                label='TDS Amt. (Mapped)'
                className='form_style'
                style={{ width: '50%' }}
                value={tdstotalSum}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ fontWeight: 500 }}>
                DEDUCTION AMT.
                <br />
                (Mapped) :
              </p>
              <TextField
                size='small'
                label='Deduction Amt.(Mapped)'
                className='form_style'
                style={{ width: '50%' }}
                value={dedtotalSum}
              />
            </div>

            <Button
              className='form_style'
              variant='outlined'
              // startIcon={<Send />}

              disabled={
                isChecked.length === 0 ||
                state?.amount - totalSum < 0 ||
                openSnackbar
                  ? true
                  : false
              }
              onClick={submitMappedRow}
            >
              Submit
            </Button>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  async function getData(id, rid, pl) {
    try {
      const res = await getMapcollection(id, rid, pl)
      if (res.status !== 204) {
        setData(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  console.log('hello', querycode, checkeddisabled)

  const handleCheckboxChange = (event, row) => {
    console.log(
      'disabled',
      data.find((item) => item.p_id === row.p_id)?.query_code,
      data
        .filter(
          (item) =>
            item.query_code ===
            data.find((item) => item.p_id === row.p_id)?.query_code
        )
        .find((item) => item.amount_type === 1)?.balance_amount === 0
        ? true
        : false
    )
    setQuerycode(data.find((item) => item.p_id === row.p_id)?.query_code)
    setCheckeddisabled(
      data
        .filter(
          (item) =>
            item.query_code ===
            data.find((item) => item.p_id === row.p_id)?.query_code
        )
        .find((item) => item.amount_type === 1)?.balance_amount === 0
        ? true
        : false
    )
    setIsChecked((prev) => {
      const newChecked = { ...prev }
      if (event.target.checked) {
        newChecked[row.p_id] = {
          ...row,
          ded_amt: row.ded_amt ? row.ded_amt : 0,
          amount:
            row.amount_type === 4 ? row.total_amount : row.entries_mapped_amt,
          tds_amt: row.paid_tds_amt ? row.paid_tds_amt : row.tds_amt,
          receipt_id: state?.id,
          payment_type: row?.amount_type,
        }
      } else {
        delete newChecked[row.p_id]
      }
      return newChecked
    })
    SetEditable(true)
    console.log('row', row)
  }

  const handleCellEditCommit = (params) => {
    const updatedRows = data.map((row) => {
      if (row.p_id === params.row.p_id) {
        return { ...row, [params.field]: params.value }
      }
      return row
    })
    setData(updatedRows)
  }

  const handleEditMappedamount = (params) => {
    setIsChecked((prev) => ({
      ...prev,
      [params.row.p_id]: {
        ...prev[params.row.p_id],
        amount: params.value,
      },
    }))
  }

  const handleTdsEditMappedamount = (params) => {
    setIsChecked((prev) => ({
      ...prev,
      [params.row.p_id]: {
        ...prev[params.row.p_id],
        tds_amt: params.value,
      },
    }))
  }

  const handledeductionEditMappedamount = (params) => {
    setIsChecked((prev) => ({
      ...prev,
      [params.row.p_id]: {
        ...prev[params.row.p_id],
        ded_amt: params.value,
      },
    }))
  }

  async function submitMappedRow() {
    const sendData = {
      customer_id: state.customer_id,
      id: state.id,
      query_details: Object.values(isChecked),
      is_prelogin: state.is_prelogin,
    }
    try {
      const res = await postMappedApi(sendData, setError)
      if (res !== 204 && res !== 406) {
        setSeverity('success')
        setMes('Submitted Successfully')
        setOn(true)
        navigate('/receipt')
      }
    } catch (error) {
      setSeverity('error')
      setMes(
        'Kindly make your selections in the following order: token, advance, final'
      )
      setOn(true)
    }
  }

  console.log('steatete', state)

  React.useEffect(() => {
    if (state?.customer_id && state.id) {
      getData(state.customer_id, state.id, state.is_prelogin === 2 ? 1 : 0)
    }
  }, [state])

  React.useEffect(() => {
    const hasZeroAmount = Object.values(isChecked).some(
      (item) => item.amount === 0
    )
    console.log('hasZeroAmount', hasZeroAmount)
    setOpenSnackbar(hasZeroAmount)
    if (hasZeroAmount) {
      setSeverity('error')
      setMes('Mapped Amount cannot be zero')
      setOn(true)
    }
  }, [isChecked])

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 75,
      cellClassName: 'actions',
      renderCell: (params) => {
        return (
          <Checkbox
            checked={!!isChecked[params.row.p_id]}
            onChange={(event) => handleCheckboxChange(event, params.row)}
            disabled={params.row.is_prev_paid === 0 ? true : false}
          />
        )
      },
    },
    {
      field: 'query_code',
      headerName: 'Query Code',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'due_date',
      headerName: 'Due Date',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return params.row.due_date
          ? dayjs(params.row.due_date).format('DD-MM-YYYY')
          : 'N.A'
      },
    },
    {
      field: 'amount_type',
      headerName: 'Amount Type',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return `${
          params.row.amount_type === 1
            ? 'Token Amount'
            : params.row.amount_type === 2
            ? 'Advance Amount'
            : params.row.amount_type === 3
            ? 'Final Amount'
            : params.row.amount_type === 4
            ? 'Additional Service'
            : 'N.A'
        }`
      },
    },

    {
      field: 'trans_id',
      headerName: 'Transaction Id',
      flex: 1,
      minWidth: 150,
    },

    {
      field: 'total_amount',
      headerName: 'Total Amt',
      flex: 1,
      minWidth: 150,
    },

    {
      field: 'total_mapped_amt',
      headerName: 'Mapped Total',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'entries_mapped_amt',
      headerName: 'Mapped In This Entry',
      flex: 1,
      minWidth: 150,
    },

    {
      field: 'balance_amount',
      headerName: 'Pending Amt',
      flex: 1,
      minWidth: 150,

      renderCell: (params) => <p>{params.row.balance_amount}</p>,
    },

    {
      field: 'mapped_amount',
      headerName: 'Amt To Mapped',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return isChecked[params.row.p_id] ? (
          <TextField
            type='number'
            variant='outlined'
            value={isChecked[params.row.p_id]?.amount}
            size='small'
            onChange={(event) => {
              let newValue = Number(event.target.value)

              // the new value does not exceed the balance amount
              // if (newValue > params?.row?.balance_amount) {
              //   newValue = params?.row?.balance_amount
              // }
              handleEditMappedamount({ row: params.row, value: newValue })
            }}
          />
        ) : (
          <p>
            {mappedRowData[params.row.p_id]
              ? mappedRowData[params.row.p_id]
              : params?.row?.balance_amount}
          </p>
        )
      },
    },

    {
      field: 'tds',
      headerName: 'TDS',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return isChecked[params.row.p_id] ? (
          <TextField
            type='number'
            variant='outlined'
            value={isChecked[params.row.p_id]?.tds_amt}
            size='small'
            onChange={(event) => {
              const newValue = Number(event.target.value)
              if (newValue >= 0) {
                handleTdsEditMappedamount({ row: params.row, value: newValue })
              }
            }}
          />
        ) : (
          <p>
            {mappedRowData[params.row.p_id]
              ? mappedRowData[params.row.p_id]
              : params?.row?.paid_tds_amt
              ? params?.row?.paid_tds_amt
              : params?.row?.tds_amt}
          </p>
        )
      },
    },

    {
      field: 'deduction',
      headerName: 'Deduct',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return isChecked[params.row.p_id] ? (
          <TextField
            type='number'
            variant='outlined'
            value={isChecked[params.row.p_id]?.ded_amt}
            size='small'
            onChange={(event) => {
              const newValue = Number(event.target.value)
              handledeductionEditMappedamount({
                row: params.row,
                value: newValue,
              })
            }}
          />
        ) : (
          <p>
            {mappedRowData[params.row.p_id]
              ? mappedRowData[params.row.p_id]
              : params?.row?.ded_amt
              ? params?.row?.ded_amt
              : 0}
          </p>
        )
      },
    },
    {
      field: 'balanceamount',
      headerName: 'Balance Amt',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return isChecked[params.row.p_id] ? (
          <TextField
            type='number'
            variant='outlined'
            disabled
            value={
              isChecked[params.row.p_id]?.balance_amount -
              (isChecked[params.row.p_id]?.amount +
                isChecked[params.row.p_id]?.tds_amt +
                isChecked[params.row.p_id]?.ded_amt)
            }
            size='small'
          />
        ) : (
          <p>
            {mappedRowData[params.row.p_id]
              ? mappedRowData[params.row.p_id]
              : 0}
          </p>
        )
      },
    },
  ]

  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <StripedDataGrid
        rows={data?.map((item, index) => ({
          ...item,
          id: index + 1,
        }))}
        getRowId={(row) => row.id}
        columns={columns}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        disableRowSelectionOnClick
      />
      <Card>
        <CardContent>
          <CustomFooter />
        </CardContent>
      </Card>
    </Box>
  )
}
